export const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
};

export const rgbToHex = (red: number, green: number, blue: number) => {
  const r = Math.max(0, Math.min(255, red));
  const g = Math.max(0, Math.min(255, green));
  const b = Math.max(0, Math.min(255, blue));
  const hex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
    .toUpperCase();

  return `#${hex}`;
};

export const hsbToRgb = (hue: number, saturation: number, brightness: number) => {
  const basicHue = hue / 360;
  const basicSaturation = saturation / 100;
  const basicBrightness = brightness / 100;

  let red = 0;
  let green = 0;
  let blue = 0;
  const hueInt = Math.floor(basicHue * 6);
  const hueFloat = basicHue * 6 - hueInt;
  const auxiliaryValueP = basicBrightness * (1 - basicSaturation);
  const auxiliaryValueQ = basicBrightness * (1 - hueFloat * basicSaturation);
  const auxiliaryValueT = basicBrightness * (1 - (1 - hueFloat) * basicSaturation);
  switch (hueInt % 6) {
    case 0: red = basicBrightness; green = auxiliaryValueT; blue = auxiliaryValueP; break;
    case 1: red = auxiliaryValueQ; green = basicBrightness; blue = auxiliaryValueP; break;
    case 2: red = auxiliaryValueP; green = basicBrightness; blue = auxiliaryValueT; break;
    case 3: red = auxiliaryValueP; green = auxiliaryValueQ; blue = basicBrightness; break;
    case 4: red = auxiliaryValueT; green = auxiliaryValueP; blue = basicBrightness; break;
    case 5: red = basicBrightness; green = auxiliaryValueP; blue = auxiliaryValueQ; break;
  }

  return {
    r: Math.round(red * 255),
    g: Math.round(green * 255),
    b: Math.round(blue * 255),
  };
};

export const rgbToHsb = (red: number, green: number, blue: number) => {
  const basicRed = red / 255;
  const basicGreen = green / 255;
  const basicBlue = blue / 255;
  const max = Math.max(basicRed, basicGreen, basicBlue);
  const min = Math.min(basicRed, basicGreen, basicBlue);
  const distance = max - min;
  const saturation = max === 0 ? 0 : distance / max;
  const brightness = max;
  let hue = 0;
  switch (max) {
    case min:
      hue = 0;
      break;
    case basicRed:
      hue = (basicGreen - basicBlue) / distance + (basicGreen < basicBlue ? 6 : 0);
      break;
    case basicGreen:
      hue = (basicBlue - basicRed) / distance + 2;
      break;
    case basicBlue:
      hue = (basicRed - basicGreen) / distance + 4;
      break;
  }

  hue /= 6;
  return { h: hue, s: saturation, v: brightness };
};
