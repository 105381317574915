import LCLog from '@tencent/lclog-web-sdk';

const guid = window.external?.getGuid?.()?.replace(/-/g, '') || '';
const qua = window.external?.getQUA?.() || '';
console.log('>>>>>>guid', guid);

const lcLogger = new LCLog({
  id: 'sdk-a3cea571cdc1c9a9',
  reportPv: true, // 是否主动上报pv， 默认false
  uin: guid || '',
  version: window.external?.getVersion?.() || '',
  useragent: qua,
  ext1: window.external?.getFavSyncGuid?.() || '',
  ext2: /PR=SE/.test(window.external?.getQUA?.()) ? 'sogou' : 'qq',
  ext3: '', // qbId
  beforeRequest: (msg: any) => {
    const { logType, logs } = msg || {};
    if (logType === 'log' && logs?.level === '4') {
      if (/(Script error)|PROMISE_ERROR|qbInformationBox|(\$ is not defined)|(Failed to fetch)|chrome-extension/.test(logs.msg)) {
        return false;
      }
    }

    return msg;
  },
});

const logger = new Proxy(lcLogger, {
  get(target, propKey, receiver) {
    if (['error', 'infoAll', 'report'].includes(propKey as string)) {
      const func = Reflect.get(target, propKey, receiver);
      return function (this: any, ...args: any) {
        if (['error', 'infoAll'].includes(propKey as string)) {
          console.info(...args);
        }

        if (import.meta.env.DEV) {
          return () => { };
        }

        return Reflect.apply(func, this, args);
      };
    }

    if (import.meta.env.DEV) {
      return () => { };
    }
    return Reflect.get(target, propKey, receiver);
  },
});

export const doReportPv = (params = {}) => {
  logger.report({
    msg: 'newtab_page_pv',
    report: JSON.stringify(params),
    useragent: qua,
    browserUA: window.navigator.userAgent,
  });
};

export const doReport = (params = {}) => {
  logger.report({
    msg: 'newtab_page_click',
    report: JSON.stringify(params),
    useragent: qua,
    browserUA: window.navigator.userAgent,
  });
};

console.error = (function (origin) {
  return function (...args) {
    if (['browser.login', 'browser.app', 'getAccountAndDeviceInfo'].some(x => args?.[0]?.includes?.(x))) {
      return;
    }

    origin.call(console, ...args);
  };
}(console.error));

export default logger;
