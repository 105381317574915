<script lang="ts">
import { Position } from '@/types';
import util from '@/utils/util';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'ContextMenuGroup',
  props: {
    position: {
      type: Object as PropType<Nullable<Position>>,
      default: () => ({}),
    },
    deleteText: {
      type: String,
      default: '删除',
    },
    editText: {
      type: String,
      default: '编辑',
    },
    reportParams: {
      type: Object,
      default: () => ({}),
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close', 'edit', 'delete'],
  data() {
    return {
      icon: '',
      name: '',
      showDelete: false,
    };
  },
  computed: {
    modalStyle() {
      return {
        ...this.position,
      };
    },
    reportPramsStr() {
      return util.genQueryString(this.reportParams);
    },
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  beforeUnmount() {
    document.body.removeChild(this.$el);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    edit() {
      this.close();
      this.$emit('edit');
    },
    deleteGroup() {
      this.close();
      this.$emit('delete');
    },
  },
});
</script>
<template>
  <div class="modal-wrapper">
    <div class="modal-container">
      <div
        class="mask"
        @click="close"
        @contextmenu.prevent.stop="close"
      />
      <div
        class="modal-content"
        dt-keep-report="true"
        dt-eid="quickstart_right_clk"
        :dt-params="reportPramsStr"
        :style="modalStyle"
        @contextmenu.prevent.stop=""
      >
        <button
          v-if="showEdit"
          class="modal-btn"
          dt-keep-report="true"
          dt-eid="quickstart_right_clk_edit"
          dt-imp-ignore="true"
          :dt-params="reportPramsStr"
          @click="edit"
        >
          <i class="icon" />
          <span>{{ editText }}</span>
        </button>
        <button
          class="modal-btn"
          dt-keep-report="true"
          dt-eid="quickstart_right_clk_delete"
          dt-imp-ignore="true"
          :dt-params="reportPramsStr"
          @click="deleteGroup"
        >
          <i class="icon icon-edit" />
          <span>{{ deleteText }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
