<template>
  <div class="radio" @click="toggle">
    <div class="radio-circle" :class="{ 'radio-circle--checked': isChecked }"></div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps({
  value: {
    type: Boolean,
    required: true,
  },
  canUncheck: {
    type: Boolean,
    default: true,
  }
});

const emit = defineEmits(['change']);

const isChecked = ref(props.value);

watch(() => props.value, (newValue) => {
  if(newValue !== isChecked.value) {
    isChecked.value = newValue;
  }
});

const toggle = () => {
  if (props.canUncheck || !isChecked.value) {
    isChecked.value = !isChecked.value;
    emit('change', isChecked.value);
  }
};
</script>