<template>
  <div class="group-container" @mouseover="isShowNavigation = true"
    @mouseleave="hoverItemId = ''; isShowNavigation = false">
    <draggable class="group-bar" :list="draggableGroups" v-bind="DRAG_OPTIONS" itemKey="id" @start.prevent=""
      @choose.prevent="" @move.prevent="" @change="handleDragEnd">
      <template #item="{ element: item, index }">
        <div :key="item.id" :class="['group-bar-item', activeIndex === getRealIndex(index) ? 'active' : '']"
          dt-eid="quickstart_group" :dt-params="getReportGroupItemParams(item, getRealIndex(index))"
          @click="selectGroup(getRealIndex(index))" @mouseover="hoverItemId = item.id; hoverIndex = index"
          @mouseleave="hoverItemId = ''; hoverIndex = -1"
          @contextmenu.prevent.stop="(e) => onGroupContextMenu(e, getRealIndex(index))">
          <div class="icon" :style="{ backgroundImage: `url(${getGroupIcon(getRealIndex(index))})` }" />
          <div class="text" v-if="hoverItemId === item.id">{{ item.name }}</div>
        </div>
      </template>
      <template #footer>
        <div key="add" dt-eid="quickstart_group_add" title="添加分组" dt-imp-ignore="true" class="group-bar-item"
          @mouseover="hoverItemId = 'add'" @mouseleave="hoverItemId = ''" @contextmenu.prevent.stop=""
          @click="handleAdd">
          <div class="icon">+</div>
        </div>
      </template>
    </draggable>
    <Navigation ref="navNode" :isVisible="isShowNavigation && navGroups.length > 1" :isBack="isNavBack" :count="navGroups.length"
      :activeIndex="navIndex" @click="setNavIndex" />
    <CreateGroupModal v-if="isShowModal" :dtParams="getReportCreateParams()" :position="contextMenuPosition" :groupData="groups[editIndex]"
      @add="handleSave" @close="isShowModal = false" />
    <ContextmenuGroupModal v-if="isShowContextMenu" :position="contextMenuPosition" :editText="GROUP_MENU_TEXTS.edit"
      :deleteText="GROUP_MENU_TEXTS.delete" @edit="handleShowCreateModal" @delete="handleDelete"
      @close="isShowContextMenu = false" />
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import draggable from 'vuedraggable';
import { GroupItem } from '@/types/pref';
import { GROUP_ICON_LIST } from '@/configs/global';
import CreateGroupModal from '@/components/modal/create-group/index.vue';
import ContextmenuGroupModal from '@/components/modal/contextmenu-group/index.vue';
import { Position } from '@/types';
import { GROUP_MENU_TEXTS } from '@/configs/quick-chain';
import lcLog from '@/lib/lclog';
import AssetHelper from '@/utils/asset-helper';
import DeleteModal from '@/plugins/delete-modal';
import util, { genUuid } from '@/utils/util';
import Navigation from './navigation/index.vue';
import useNavigate, { MAX_GROUP_SIZE } from './hooks/use-navigate';
import { reporter } from '@/lib/dtReport';
import useGroupAddListener from './hooks/use-group-add-listener';
import useQbLightChange from '@/hooks/use-qblight-change';

type DragData = {
  moved: {
    newIndex: number;
    oldIndex: number;
  }
};

const props = defineProps({
  defaultGroups: Array as PropType<GroupItem[]>,
});

const emit = defineEmits(['select', 'delete', 'create', 'moved']);

const DRAG_OPTIONS = {
  animation: 200,
  group: 'group',
  disabled: false,
  ghostClass: 'ghost',
};

const hoverItemId = ref();
const isShowContextMenu = ref(false);
const contextMenuPosition = ref<Nullable<Position>>(null);
const editIndex = ref(-1);
const isShowModal = ref(false);
const groups = ref(props.defaultGroups?.slice() || []);
const activeIndex = ref(0);
const isShowNavigation = ref(false);
const isQbLight = useQbLightChange();
const { navIndex, setNavIndex, hoverIndex, navGroups, isNavBack, getRealIndex, navNode, draggableGroups } = useNavigate(groups);

const getGroupIcon = (index: number) => {
  const { icon } = groups.value![index];
  const group = GROUP_ICON_LIST.find(item => item.name === icon);

  return isQbLight.value && group?.activeIcon ? group?.activeIcon : group?.defaultIcon;
};

const onGroupContextMenu = (e: any, index: number) => {
  e.preventDefault();
  const node = e.target.classList.contains('group-bar-item') ? e.target : e.target.parentNode;
  const rect = node.getBoundingClientRect();
  contextMenuPosition.value = {
    top: `${rect.top}px`,
    left: `${rect.left + 56}px`,
  };
  isShowContextMenu.value = true;
  editIndex.value = index;
  return false;
};

const selectGroup = (index: number) => {
  activeIndex.value = index;
  setNavIndex(Math.floor(index / MAX_GROUP_SIZE));
  emit('select', index);
};

const handleSave = async (group: GroupItem) => {
  try {
    const newGroups = [...groups.value];
    const groupItem = groups.value[editIndex.value];
    if (groupItem) {
      newGroups[editIndex.value] = { ...groupItem, ...group };
    } else {
      newGroups.push({ ...group, id: group.id?.toString() ?? genUuid() });
    }

    await AssetHelper.updateGroups(newGroups as GroupItem[]);
    groups.value = newGroups;
    if (!groupItem) {
      selectGroup(groups.value.length - 1);
      emit('create');
    }

    isShowModal.value = false;
    editIndex.value = -1;
  } catch (e) {
    lcLog.error('handleSave', e);
  }
};

const handleDelete = () => {
  const groupName = groups.value[editIndex.value].name;
  const modalContent = groups.value.length === 1 ? '删除分组后所有网址也会被删除，可以通过右键重新添加分组和网址' :
    `此操作将会删除“${groupName}”分组下的所有内容，确认删除吗？`;

  DeleteModal.show({
    modalValue: true,
    content: modalContent,
    dtParams: {
      group_id: editIndex.value + 1,
      group_name: groupName,
      group_position: editIndex.value + 1,
    },
    confirm: async () => {
      try {
        const newGroups = [...groups.value];
        newGroups.splice(editIndex.value, 1);
        await AssetHelper.updateGroups(newGroups as GroupItem[], [editIndex.value]);

        groups.value = newGroups;
        if (editIndex.value === activeIndex.value) {
          if (activeIndex.value === groups.value.length) {
            activeIndex.value = activeIndex.value - 1 < 0 ? 0 : activeIndex.value - 1;
          } else {
            activeIndex.value = activeIndex.value - 1 < 0 ? 0 : activeIndex.value;
          }
        } else if (editIndex.value < activeIndex.value) {
          activeIndex.value = activeIndex.value - 1 <= 0 ? 0 : activeIndex.value - 1;
        }

        setTimeout(() => {
          emit('delete', editIndex.value, groups.value.length);
        }, 200);
      } catch (e) {
        lcLog.error('handleDelete', e);
      }
    },
  });
};

const getReportCreateParams = () => {
  const isEdit = editIndex.value !== -1;
  const groupName = isEdit ? groups.value[editIndex.value].name : '';
  return {
    group_id: isEdit ? editIndex.value + 1 : groups.value.length + 1,
    group_name: groupName,
    group_position: isEdit ? editIndex.value + 1 : groups.value.length + 1,
    edit_add: isEdit ? 'edit' : 'add',
  };
}

const getReportGroupItemParams = (group: GroupItem, index: number) => {
  return util.genQueryString({
    group_id: index + 1,
    group_name: group.name,
    group_position: index + 1,
  });
};

const handleAdd = () => {
  editIndex.value = -1;
  isShowModal.value = true;
  contextMenuPosition.value = null;
}

const updateGroups = async (newGroups: GroupItem[], index: number, isUpdate = false) => {
  try {
    if (isUpdate) {
      await AssetHelper.updateGroups(newGroups);
    }

    activeIndex.value = index;
    groups.value = newGroups;
  } catch (e) {
    lcLog.error('updateGroups', e);
  }
};

const handleDragEnd = async (data: DragData) => {
  const {
    moved: { newIndex, oldIndex },
  } = data;
  const currentGroups = groups.value;
  try {
    groups.value = navGroups.value.reduce((prev, curr, currentIndex) => {
      return [...prev, ...(currentIndex === navIndex.value ? draggableGroups.value : curr)];
    }, []);
    await AssetHelper.updateGroups(groups.value, [oldIndex, newIndex]);
    emit('moved', data.moved);
    reporter.reportEvent({
      pgid: 'newpage',
      eventName: 'dt_clck',
      businessParams: {
        eid: 'quickstart_group_order',
        group_position: oldIndex + 1,
        group_name: groups.value[newIndex]?.name,
        group_icon: groups.value[newIndex]?.icon,
        group_id: oldIndex + 1,
        final_position: newIndex + 1,
      },
    });
  } catch (e) {
    // TODO: draggableGroups回滚？
    groups.value = currentGroups;
    lcLog.error('handleDragEnd', e);
  }
}

const handleShowCreateModal = () => {
  if (contextMenuPosition.value) {
    const MODAL_HEIGHT = 268; // 编辑框高度
    const top = parseInt(contextMenuPosition.value.top?.replace('px', '') ?? '0', 10);
    if (!isNaN(top) && top + MODAL_HEIGHT > window.innerHeight) {
      contextMenuPosition.value = {
        left: contextMenuPosition.value.left,
        bottom: '6px',
      }
    } 
  }

  isShowModal.value = true;
}

useGroupAddListener(handleAdd);
defineExpose({
  groups,
  activeIndex,
  updateGroups,
});
</script>
