<script lang="ts">
import { GROUP_ICON_LIST } from '@/configs/global';
import { Position } from '@/types';
import util, { genUuid } from '@/utils/util';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CreateGroup',
  props: {
    position: {
      type: Object as PropType<Nullable<Position>>,
      default: () => ({}),
    },
    groupData: {
      type: Object,
      default: null,
    },
    dtParams: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'add'],
  data() {
    return {
      groupIcons: GROUP_ICON_LIST,
      icon: GROUP_ICON_LIST[0].name,
      name: '',
      hasInput: false,
    };
  },
  computed: {
    modalStyle() {
      return this.position ? { ...this.position } : {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };
    },
    disabled() {
      const { icon, name } = this;
      return !icon || !name;
    },
    activeIndex() {
      const { icon } = this;
      const activeIndex = GROUP_ICON_LIST.findIndex(item => item.name === icon);
      return activeIndex || 0;
    },
    addText() {
      return this.groupData ? '保存' : '添加';
    },
    curDtParams() {
      const { name } = this;
      const mergeObj = name
        ? {
          group_name: name,
        }
        : {};
      return util.genQueryString({
        ...this.dtParams,
        ...mergeObj,
      });
    },
  },
  created() {
    this.getGroupIconList();
  },
  mounted() {
    if (this.groupData) {
      this.icon = this.groupData.icon;
      this.name = this.groupData.name;
    }
    document.body.appendChild(this.$el);
  },
  methods: {
    getGroupIconList() {
      $.ajax({
        url: `${window?.__API_URL__}/new_api/getGroupIcon`,
        success: (data) => {
          this.groupIcons = data.data;
        },
      });
    },
    onInput() {
      this.hasInput = true;
    },
    close() {
      this.$emit('close');
    },
    selectIcon(icon: any) {
      this.icon = icon.name;
      if (!this.hasInput && !this.name) {
        this.name = icon.cname;
      }
    },
    add() {
      const { icon, name } = this;
      if (!icon || !name) {
        return;
      }
      this.$emit('add', {
        // id: genUuid(),
        name: this.name,
        icon: this.icon,
      });
    },
    getGroupIcon(index: number) {
      const { name } = this.groupIcons[index];
      const iconData = this.groupIcons.find(item => item.name === name);
      if (this.activeIndex === index) {
        return iconData?.activeIcon;
      }
      return iconData?.defaultIcon;
    },
  },
});
</script>
<template>
  <div class="modal-container" :dt-params="curDtParams" dt-keep-report="true" dt-eid="quickstart_group_edit_add_panel">
    <div class="mask" @click="close" />
    <div class="modal-content modal-box-shadow" :style="modalStyle">
      <div class="modal-title">
        分组图标
      </div>
      <div class="icon-container">
        <div class="icon-list">
          <div v-for="(groupIcon, index) in groupIcons" :key="index"
            :class="['icon-item', activeIndex === index ? 'active' : '']" @click="selectIcon(groupIcon)">
            <img :src="getGroupIcon(index)" alt="">
          </div>
        </div>
      </div>
      <div class="modal-title form-label">
        分组名称
      </div>
      <div class="form-content">
        <input v-model="name" class="form-input" maxlength="6" placeholder="请输入分组名称" type="text" @input="onInput()"
          @keyup.enter="add">
      </div>
      <div class="modal-footer">
        <button :disabled="disabled" dt-keep-report="true" :dt-params="curDtParams" dt-eid="group_edit_add_confirm"
          class="modal-btn btn-primary" @click="add">
          {{ addText }}
        </button>
        <button class="modal-btn btn-default" dt-keep-report="true" :dt-params="curDtParams"
          dt-eid="group_edit_add_cancel" @click="close">
          取消
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
