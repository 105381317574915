<script setup lang="ts">
import { defineAsyncComponent, nextTick, onMounted, ref, reactive, getCurrentInstance, ComponentInternalInstance, shallowRef } from 'vue';
import QuickChain from './page/quick-chain/index.vue';
import Header from '@components/header/index.vue';
import Light from '@components/light/index.vue';
import SlideGuide from '@components/slide-guide/index.vue';
import Navigation from '@components/navigation/index.vue';
import TopTip from '@components/top-tip/index.vue';
import util, { debounce, genUuid } from '@/utils/util';
import { initContextEvent } from './common/events';
import rightContextMenu from './plugins/right-context-menu';
import { Position } from './types';
import CreateWidgetModal from '@/components/modal/create-widget/index.vue';
import { GROUP_ICON_LIST } from './configs/global';
import { setIconSize } from '@/utils/size';
import AssetHelper from '@/utils/asset-helper';
import lcLog from '@/lib/lclog';
import { openSkinSidebar } from '@/lib/skin';
import useErrorMessage from '@/hooks/use-error-message';
import { onLoginStatusChanged } from '@/lib/login';
import { ListItem } from './types/pref';

const mainWrapper = ref();
const showSlideGuide = ref(true);
const state = reactive({
  currentIndex: 0,
  isScrolling: false,
  sectionsCount: 2,
  canSlide: false,
});
const fullPageStyle = ref({});
const showHeader = ref(false);
const headerRef = ref();
const feedsList = shallowRef();
const feedsListRef = ref();
const showData = ref(!!window.isShowOperation);
const showTopTip = ref(false);
const topTipRef = ref();
const section1Ref = ref();
const { appContext } = getCurrentInstance() as ComponentInternalInstance;
const isEmptyData = ref(!!window.isEmptyData); // 是否分组和快链都为空
const showContent = ref(true); // 是否显示所有内容
const showFirstPage = ref(false); // 是否显示第一屏
const showCreateWidgetModal = ref(false); // 是否显示添加快链
const addedSites = ref<ListItem[]>([]);
const $search = ref();
const searchBox = ref();
const isPrerender = ref(!!window.__PRERENDER_INJECTED);
const quickchain = ref(null);

useErrorMessage();

onMounted(async () => {
  nextTick(() => {
    searchBox.value = window.qbsearch?.init?.({
      selector: '.search',
      adtag: 'newtab',
      logo: 'top',
      btn: 'text',
      registry: false,
      width: '100%',
      prerender: true,
    });
  });
  await initUserData();
  onLoginStatusChanged(debounce(() => {
    initUserData(true);
  }, 50));
  showFirstPage.value = true;

  const handleMouseWheel = debounce(handleScroll.bind(this), 40, true);
  mainWrapper.value.addEventListener('wheel', handleMouseWheel);
  document.getElementById('section2')?.addEventListener('scroll', () => {
    listenFeedsTabScroll();
  });
  window.addEventListener('resize', handleResize);
  initContextEvent(showContextMenu, () => rightContextMenu.hide());
  initShowTopTip();
  nextTick(() => {
    showHeaderFn();

    feedsList.value = defineAsyncComponent(() => import('@/components/feeds-list/feeds-list.vue'));
  });
});

const initUserData = async (isRestore = false) => {
  const allUserData = await AssetHelper.getAssets();
  const { groups } = allUserData || {};
  isEmptyData.value = !groups || groups.length === 0;
  resizeSearchBox(false);

  if (isRestore) {
    onRestore(allUserData);
  }
};

const handleFeedsDataChange = (canSlide: boolean) => {
  state.canSlide = canSlide;
};

setIconSize(!showData.value || isEmptyData.value);

const showHeaderFn = () => {
  const { innerWidth } = window;
  let show = true;
  try {
    const $header = headerRef.value.$el;
    const headerWidth = $header.offsetWidth;
    if (state.currentIndex > 0 && ((headerWidth > 0 && ((innerWidth - 1200) / 2) < headerWidth) || headerWidth === 0)) {
      show = false;
    } else if (showTopTip.value) {
      if (((innerWidth - topTipRef.value.$el.offsetWidth) / 2) < headerWidth) {
        show = false;
      }
    }
  } catch (error) {
    show = false;
  }
  showHeader.value = show;
};

const initShowTopTip = () => {
  util.setCookie('visited_pure', true, 36500);
  const showStr = window.localStorage.getItem('SHOW_TOP_TIP');
  if (window.pageRedirectFrom !== 'gameandlive') {
    showTopTip.value = false;
    return;
  }
  if (showStr === null || showStr === 'true') {
    showTopTip.value = true;
  } else {
    showTopTip.value = false;
  }
};

const closeTopTip = () => {
  showTopTip.value = false;
  window.localStorage.setItem('SHOW_TOP_TIP', 'false');
  showHeaderFn();
};

const showContextMenu = (e: MouseEvent) => {
  if (state.currentIndex !== 0) return false;
  const top = e.pageY; // 获取鼠标点击的 x 坐标
  const left = e.pageX; // 获取鼠标点击的 y 坐标
  const position: Position = {
    top: `${top}px`,
    left: `${left + 8}px`,
  };
  rightContextMenu.show({
    position,
    list: [
      {
        label: '添加网址导航',
        action: addSite,
      },
      {
        label: '添加分组',
        action: addGroup,
      },
      {
        label: '更换皮肤',
        action: changeSkin,
      },
    ],
  });
};

const addSite = () => {
  if (isEmptyData.value) {
    const { widgets = [] } = AssetHelper.getUserData() || {};
    addedSites.value = widgets?.[0] || [];
    showCreateWidgetModal.value = true;
  } else {
    appContext.config.globalProperties.$bus.$emit('addWidget');
  }
  rightContextMenu.hide();
};

const addSiteConfirm = async (site: ListItem) => {
  if (!site) {
    return;
  }

  try {
    const id = genUuid();
    await AssetHelper.updateGroups([{
      id,
      name: '新增分组',
      icon: GROUP_ICON_LIST[0].name,
    }]);
    const { widgets = [] } = AssetHelper.getUserData() || {};
    if (!widgets?.[0]) {
      widgets[0] = [site];
    } else {
      widgets[0].push(site);
    }

    await AssetHelper.updateWidgets(id, widgets[0]);
    showFirstPage.value = false;
    isEmptyData.value = false;
    addedSites.value = widgets[0];
    setTimeout(() => {
      showFirstPage.value = true;
    }, 100);
    resizeSearchBox(true);
  } catch (e) {
    lcLog.error(e);
  }
};
const addGroup = () => {
  appContext.config.globalProperties.$bus.$emit('addGroup');
  rightContextMenu.hide();
};
const changeSkin = () => {
  openSkinSidebar();
  rightContextMenu.hide();
};

const handleResize = () => {
  showHeaderFn();
  setIconSize(!showData.value || isEmptyData.value);
  const clientHeight = window.innerHeight;
  const curFullPage = mainWrapper.value?.querySelectorAll('.vue-full-page-scroll')?.[state.currentIndex];
  const $section = curFullPage?.querySelector?.('.section');
  if (!$section) {
    return;
  }

  const pageHeight = $section.scrollHeight;
  let y = 0;
  if (clientHeight >= Math.round(pageHeight)) {
    y = pageHeight;
  } else if (Math.round(pageHeight) >= clientHeight) {
    y = clientHeight;
  }
  fullPageStyle.value = {
    transform: `translateY(${y * -1 * state.currentIndex}px)`,
    transition: 'translate 0.1s ease',
  };
  listenFeedsTabScroll(false);
};

const scrollTo = (index: number) => {
  if (state.isScrolling || index === state.currentIndex || !state.canSlide) {
    return;
  }
  const clientHeight = window.innerHeight;
  const curFullPage = mainWrapper.value?.querySelectorAll('.vue-full-page-scroll')?.[state.currentIndex];
  const $section = curFullPage?.querySelector('.section');
  if (!$section) {
    return;
  }

  const pageHeight = $section.scrollHeight;
  $section.scrollTop = 0;
  state.isScrolling = true;
  setSearchBoxStyle(index);

  showSlideGuide.value = state.currentIndex >= index;
  state.currentIndex = index;
  let y = 0;
  if (clientHeight >= Math.round(pageHeight)) {
    y = pageHeight;
  } else if (Math.round(pageHeight) >= clientHeight) {
    y = clientHeight;
  }
  fullPageStyle.value = {
    transform: `translateY(${(y) * -1 * state.currentIndex}px)`,
  };

  if (index === 1) {
    feedsListRef.value?.initPCFeeds?.();
  }

  requestAnimationFrame(() => {
    showHeaderFn();
    state.isScrolling = false;
    searchBox.value?.closePop?.();
  });
};

const setSearchBoxStyle = (index: number) => {
  $search.value.classList.remove('no-animate');
  const clsName = 'scroll-second-page';
  if (state.currentIndex !== 2) {
    if (state.currentIndex < index) {
      $search.value.classList.add(clsName);
    } else {
      $search.value.classList.remove(clsName);
    }
  }

  $search.value.style.position = state.currentIndex < index ? 'fixed' : 'absolute';
};

const listenFeedsTabScroll = (checkContainList = true) => {
  const curFullPage = mainWrapper.value?.querySelectorAll('.vue-full-page-scroll')?.[state.currentIndex];
  const $newsWrap = curFullPage?.querySelector('.tx-news-wrap');
  const $tab = $newsWrap?.querySelector('.tx-news-tab');
  if (!$tab) {
    return;
  }

  const { y: offsetTop, left } = $newsWrap.getBoundingClientRect() ?? {};
  const hasCls = $newsWrap.classList?.contains('tx-news-fixed');
  if (offsetTop <= 64 && (!hasCls || !checkContainList)) {
    $newsWrap.classList.add('tx-news-fixed');
    $tab.style.left = left >= 0 ? `${left}px` : '0';
  } else if (offsetTop > 64 && (hasCls || !checkContainList)) {
    $newsWrap.classList.remove('tx-news-fixed');
    $tab.style.left = '0px';
  }
};

const handleScroll = (event: WheelEvent) => {
  if (state.isScrolling || !state.canSlide || !showData.value) {
    return;
  }

  const curFullPage = mainWrapper.value?.querySelectorAll('.vue-full-page-scroll')?.[state.currentIndex];
  const $section = curFullPage?.querySelector('.section');
  if (!$section) {
    return;
  }

  const threshold = 1;
  const sectionScrollTop = $section.scrollTop;
  listenFeedsTabScroll();
  const { deltaY } = event;
  const clientHeight = window.innerHeight;
  const pageHeight = $section.scrollHeight;
  let canAnimate = false;
  canAnimate = pageHeight <= clientHeight + threshold || sectionScrollTop + threshold >= pageHeight - clientHeight;
  console.log('%c Line:394 🍇 pageHeight', 'color:#465975', sectionScrollTop, pageHeight, clientHeight);
  if (deltaY < 0 && state.currentIndex > 0) {
    canAnimate = sectionScrollTop === 0;
  }

  const maxDeltaY = util.isMac() ? 0.5 : 1;
  if (canAnimate) {
    const toIndex = deltaY > maxDeltaY
      ? Math.min(state.currentIndex + 1, state.sectionsCount - 1) : Math.max(state.currentIndex - 1, 0);
    scrollTo(toIndex);
    if (state.currentIndex === 1) {
      feedsListRef.value?.loadMore?.();
    }
  }
};

const onNavClick = (index: number) => {
  scrollTo(index);
};

const onRestore = (res: any) => {
  const { widgets = [], groups = [] } = res || {};
  quickchain.value?.restoreHistory?.(widgets, groups);
};

const showUserData = ({ show, animate }: {
  show: boolean,
  animate: boolean,
}) => {
  showData.value = show;
  nextTick(() => {
    resizeSearchBox(animate);
  });
};

const resizeSearchBox = (animate: boolean) => {
  const $search = document.getElementsByClassName('search')?.[0] as HTMLElement;
  if (!$search) {
    return;
  }

  if (!animate) {
    $search.classList.add('no-animate');
  } else {
    $search.classList.remove('no-animate');
  }

  if (!showData.value || isEmptyData.value) {
    $search.classList.add('w-750');
    if (state.currentIndex > 0) {
      scrollTo(0);
    }
  } else {
    $search.classList.remove('w-750');
    setIconSize(!showData.value || isEmptyData.value);
  }
};

const setGroupEmpty = (groupLen: number) => {
  isEmptyData.value = groupLen === 0;
  resizeSearchBox(true);
};

</script>

<template>
  <Header
    ref="headerRef"
    :style="{ visibility: `${showHeader ? 'visible' : 'hidden'}` }"
    @restore="onRestore"
    @showUserData="showUserData"
  />
  <div
    v-show="showContent"
    ref="mainWrapper"
    class="main-wrapper h-full"
  >
    <div
      ref="$search"
      class="search"
    />
    <div
      v-if="!isPrerender"
      class="full-page-wrapper"
      :style="fullPageStyle"
    >
      <div class="vue-full-page-scroll">
        <div
          v-if="showFirstPage"
          id="section1"
          ref="section1Ref"
          class="section quick-chain-section"
        >
          <div class="section-container h-full">
            <TopTip
              v-if="showTopTip"
              ref="topTipRef"
              @close="closeTopTip()"
            />
            <!-- <div class="blank" /> -->
            <QuickChain
              ref="quickchain"
              :style="{ visibility: showData ? 'visible' : 'hidden' }"
              class="section-chain-list h-full"
              @setGroupEmpty="setGroupEmpty"
            />
          </div>
        </div>
      </div>
      <div class="vue-full-page-scroll">
        <div
          id="section2"
          class="section section2"
        >
          <component
            :is="feedsList"
            ref="feedsListRef"
            :showData="showData"
            :onDataChange="handleFeedsDataChange"
          />
        </div>
      </div>
    </div>
  </div>
  <template v-if="!isPrerender">
    <Light />
    <SlideGuide
      v-if="showSlideGuide && state.canSlide && showData"
      @on-click="scrollTo(1)"
    />
    <Navigation
      v-if="state.canSlide && showData"
      :activeIndex="state.currentIndex"
      @nav-click="onNavClick"
    />
    <CreateWidgetModal
      v-if="showCreateWidgetModal"
      :reportParams="{}"
      :addedSites="addedSites"
      @add="addSiteConfirm"
      @close="showCreateWidgetModal = false"
    />
  </template>
</template>
<style lang="less" scoped>
@import './App.less';
</style>
<style lang="less">
.scroll-second-page {
  &.search {
    transition: top 0.3s ease, width 0.5s ease;
  }

  .qbsbox-container {
    .qbsbox-clock {
      visibility: hidden;
    }
  }

  &.search {
    top: -80px;
  }

  &.w-750 {
    &.search {
      top: -80px;
    }
  }

  .qbsbox-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
}

.w-750 {
  &.search {
    top: 26vh;
  }
}

#section1 {
  .section-chain-list {
    display: flex;
    flex-direction: column;

    .content {
      flex: 1;
    }
  }
}
</style>
