<script lang="ts">
import { defineComponent } from 'vue';
import util from '@/utils/util';
import global from '@/configs/global';

interface Position {
  left: string;
  top: string;
  zIndex?: number;
}

export default defineComponent({
  name: 'SiteList',
  components: {
  },
  props: {
    addedSites: {
      type: Array,
      default: () => ([]),
    },
    reportParams: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'add', 'delete'],
  data() {
    return {
      groups: ['全部'],
      activeIndex: 0,
      allSites: [],
      sites: [],
      icon: '',
      name: '',
      position: {} as Position,
      showModal: false,
      searchWord: '',
      timer: null,
      currentPage: 1,
      pageSize: 30,
      pageSites: [],
      showFullTip: false,
      defaultImg: global.DEFAULT_ICON_IMAGE,
      tabs: [
        {
          label: '网址导航',
          id: 'WZDH',
        },
        {
          label: '小组件',
          id: 'XZJ',
        },
      ],
      activeTab: 'WZDH',
    };
  },
  computed: {
    total() {
      const { groups, activeIndex, sites } = this;
      let len = 0;
      if (activeIndex === 0) {
        len = sites.length;
      } else {
        len = sites.filter(site => site.group === groups[activeIndex]).length;
      }
      return len;
    },
    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    showPrevEllipsis() {
      return this.currentPage > 1;
    },
    showNextEllipsis() {
      return this.currentPage < this.pageCount;
    },
    pages() {
      const { currentPage } = this;
      const { pageCount } = this;
      let startPage = currentPage - 1;
      let endPage = currentPage + 2;
      let preArr = [];
      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(startPage + 2, pageCount);
      }

      if (endPage > pageCount) {
        endPage = pageCount;
        startPage = Math.max(endPage - 2, 1);
      }
      let sufArr = ['...', pageCount];

      if (startPage > 1) {
        preArr = [1, '...'];
      }
      if (endPage > pageCount - 1) {
        sufArr = [];
      }

      const pages = [...preArr];

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages.concat(sufArr);
    },
    fitClsName() {
      return this.pageSites.length >= 10 ? '' : 'auto-fit';
    },
  },
  created() {
    this.getChainList();
  },
  methods: {
    onGroupClick(index: number) {
      this.activeIndex = index;
      this.changePage(1);
    },
    add(e) {
      e.preventDefault();
      const rect = e.target.getBoundingClientRect();
      (this.position as Position | null) = {
        top: `${rect.top + rect.height + 12}px`,
        left: `${rect.left}px`,
        zIndex: 1003,
      };
      this.showModal = true;
    },
    addSite(index: number, event) {
      event.preventDefault();
      const site = this.pageSites[index];
      // this.$message({
      //   text: 'Operation successful',
      //   icon: 'success-icon',
      //   type: 'success',
      // });
      this.$emit('add', site);
    },
    deleteSite(index: number, event) {
      event.preventDefault();
      const site = this.pageSites[index];
      const { url } = site;
      const addedIndex = this.addedSites.findIndex(site => site.url === url);
      this.$emit('delete', addedIndex);
    },
    onSearch(e) {
      this.clearTimer();
      this.timer = setTimeout(() => {
        const value = e.target.value.trim();
        this.activeIndex = 0;
        if (value)  {
          this.sites = this.allSites.filter(site => site.title.includes(value));
          this.pageSites = this.sites.filter(site => site.title.includes(value));
        } else {
          this.activeIndex = 0;
          this.sites = this.allSites;
          this.changePage(this.currentPage);
        }
      }, 500);
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
    checkSiteAdded(site) {
      const { title, url } = site;
      return this.addedSites.find((item: any) => item.title === title && url === item.url);
    },
    async getChainList() {
      $.ajax({
        url: `${window.__API_URL__}/new_api/getChainList`,
        success: (data) => {
          data.data.forEach((site) => {
            if (!site.group) {
              site.group = '全部';
            }
          });
          this.sites = data.data;
          this.allSites = data.data;
          this.getGroup();
          this.changePage(1);
        },
      });
    },
    getGroup() {
      const { sites, groups } = this;
      sites.forEach((site) => {
        const hasGroup = groups.includes(site.group);
        if (!hasGroup) {
          groups.push(site.group);
        }
      });
      const otherIndex = groups.indexOf('其他');
      if (otherIndex > -1) {
        groups.splice(otherIndex, 1);
        groups.push('其他');
      }
    },
    changePage(page) {
      if (!Number.isInteger(page)) {
        return;
      }
      this.currentPage = page;
      const { sites, currentPage, pageSize, activeIndex, groups } = this;
      if (activeIndex === 0) {
        this.pageSites = this.sites;
      } else {
        this.pageSites = sites.filter(site => site.group === groups[activeIndex]);
      }
      this.pageSites = this.pageSites?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    },
    getDtParams(site) {
      return util.genQueryString({
        jump_url: site.url,
        name: site.title,
      });
    },
  },
});
</script>
<template>
  <div
    class="site-list-container"
  >
    <div class="form-content">
      <input
        v-model="searchWord"
        type="text"
        placeholder="请输入想要搜索的网站"
        class="form-input create-widget-search-input"
        @input="onSearch"
      >
      <i class="icon-search" />
    </div>
    <div class="site-group-list">
      <div
        v-for="(group, index) in groups"
        :key="index"
        :class="[activeIndex === index ? 'active' : '', 'site-group']"
        @click="onGroupClick(index)"
      >
        {{ group }}
      </div>
    </div>
    <div
      v-if="pageSites.length"
      :class="['site-list', fitClsName]"
    >
      <a
        v-for="(site, index) in pageSites"
        :key="index"
        dt-eid="quickstart"
        dt-imp-ignore="true"
        :href="site.url"
        target="_blank"
        class="site-item"
      >
        <span
          class="site-bg"
          :style="{backgroundImage: `url(${site.img || defaultImg})`}"
        />
        <i
          class="site-title"
          @click="(event) => event.preventDefault()"
        >{{ site.title }}</i>
        <i
          v-if="!checkSiteAdded(site)"
          class="site-icon"
          dt-keep-report="true"
          dt-imp-ignore="true"
          :dt-params="getDtParams(site)"
          dt-eid="add_from_panel"
          @click="(event) => addSite(index, event)"
        />
        <i
          v-else
          class="site-icon added"
          @click="(event) => deleteSite(index, event)"
        >已添加</i>
      </a>
    </div>
    <div
      v-else
      class="empty-sites"
    >
      暂无内容，试试搜索别的网站吧
    </div>
    <div
      class="pagination-list"
      :style="{visibility: total > pageSize ? 'visible' : 'hidden'}"
    >
      <button
        class="arrow"
        :disabled="!showPrevEllipsis"
        @click="changePage(currentPage - 1)"
      >
        <div class="icon-arrow icon-arrow-left" />
      </button>
      <div class="pagination-item">
        <button
          v-for="page in pages"
          :key="page"
          :class="{ 'pagination-text': true, active: currentPage === page }"
          @click="changePage(page)"
        >
          {{ page }}
        </button>
      </div>
      <button
        class="arrow"
        :disabled="!showNextEllipsis"
        @click="changePage(currentPage + 1)"
      >
        <div class="icon-arrow icon-arrow-right" />
      </button>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
