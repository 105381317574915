import { ComponentInternalInstance, getCurrentInstance, onMounted, onUnmounted } from 'vue';

export default function useGroupAddListener(callback: () => void) {
  const { appContext } = getCurrentInstance() as ComponentInternalInstance;

  onMounted(() => {
    appContext.config.globalProperties.$bus.$on('addGroup', callback);
  });

  onUnmounted(() => {
    appContext.config.globalProperties.$bus.$off('addGroup');
  });
}

