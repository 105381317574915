<template>
  <div v-show="isVisible" ref="nodeRef" class="group-navigation-bar">
    <div :class="['navigation', isBack && 'blur']">
      <span class="nav-upper" @click="handleClick(activeIndex > 0 ? activeIndex - 1 : activeIndex)" />
      <div v-for="(_, index) in count" :key="index" class="cycle" :class="{ active: activeIndex === index }"
        @click="handleClick(index)" />
      <span class="nav-down" @click="handleClick(activeIndex < count - 1 ? activeIndex + 1 : activeIndex)" />
    </div>
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>

<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
  activeIndex: {
    type: Number,
    default: 0,
  },
  isVisible:{
    type: Boolean,
    default: true,
  },
  isBack: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['click']);

const nodeRef = ref<HTMLElement>();

const handleClick = (index: number) => {
  emit('click', index);
};

defineExpose({
  getNavHeight: () => nodeRef.value?.clientHeight,
});

</script>
