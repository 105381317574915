
import { ref } from 'vue';

export interface EyeDropperOpenOptions {
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal
   */
  signal?: AbortSignal
}

export interface EyeDropper {
  [Symbol.toStringTag]: 'EyeDropper';
  open: (options?: EyeDropperOpenOptions) => Promise<{ sRGBHex: string }>;
  new(): EyeDropper;
}

export interface UseEyeDropperOptions {
  /**
   * Initial sRGBHex.
   *
   * @default ''
   */
  initialValue?: string
}

/**
 * Reactive [EyeDropper API](https://developer.mozilla.org/en-US/docs/Web/API/EyeDropper_API)
 *
 * @see https://vueuse.org/useEyeDropper
 */
export default function useEyeDropper(options: UseEyeDropperOptions = {}) {
  const { initialValue = '' } = options;
  const sRGBHex = ref(initialValue);
  const isSupported = ref(typeof window !== 'undefined' && 'EyeDropper' in window);

  async function open(openOptions?: EyeDropperOpenOptions) {
    if (!isSupported.value) {
      return;
    }

    const eyeDropper: EyeDropper = new (window as any).EyeDropper();
    const result = await eyeDropper.open(openOptions);
    sRGBHex.value = result.sRGBHex;
    return result;
  }

  return { isSupported, sRGBHex, open };
}

export type UseEyeDropperReturn = ReturnType<typeof useEyeDropper>;
