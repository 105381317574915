const MAX_SITE_NUM = 60;
const DEFAULT_ICON_IMAGE = '//stdl.qq.com/stdl/qb9/favorite-tab/default_icon.png';

const DEFAULT_SITES_ONE = [
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_T_K3aywRvQP.png',
    title: '腾讯文档',
    url: 'https://docs.qq.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_3zjFdgXZVFb.png',
    title: '印象笔记',
    url: 'https://www.yinxiang.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_YDABj68oKR6.png',
    title: 'QQ邮箱',
    url: 'https://mail.qq.com/',
  },
  // {
  //   img: 'http://stdl.qq.com/stdl/newtabcms/icon/0127_300.png',
  //   title: '163邮箱',
  //   url: 'http://mail.163.com/',
  // },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_2sEJh_s1QSd.png',
    title: '腾讯会议',
    url: 'https://meeting.tencent.com/activities/index.html?fromSource=sem2_gwzcw.3894088.3894088.3894088',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_9gWjIiw9Bew.jpg',
    title: '36Kr',
    url: 'https://www.36kr.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0691_300.png',
    title: '艾瑞网',
    url: 'http://www.iresearch.cn/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_zE8_wZxfXHY.png',
    title: '简书',
    url: 'http://www.jianshu.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_JyWuV9-zmyd.png',
    title: '粉笔网',
    url: 'http://fenbi.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0050_300.png',
    title: '有道翻译',
    url: 'http://www.youdao.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_i0f1L__P2_C.png',
    title: '搜狗百科',
    url: 'https://baike.sogou.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/1274_300.png',
    title: '腾讯翻译君',
    url: 'http://fanyi.qq.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/1308_300.png',
    title: '腾讯课堂',
    url: 'http://ke.qq.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_3fZaDS-I1xf.jpg',
    title: '网易公开课',
    url: 'https://open.163.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_tkUNhqtjlcf.jpg',
    title: '国图公开课',
    url: 'http://open.nlc.cn/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_lQdrfMb1Xdd.png',
    title: '北京大学公开课',
    url: 'http://opencourse.pku.edu.cn/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_hZCujfOOjZc.png',
    title: 'EnglishClub',
    url: 'https://www.englishclub.com/about/learn-english_ch.php',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_NrB0BzrSaqC.jpeg',
    title: '扇贝单词',
    url: 'https://web.shanbay.com/web/main',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_q4Ijm0kA4MM.png',
    title: 'W3School',
    url: 'https://www.w3school.com.cn/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_vqhG7s-hSyx.png',
    title: 'TED',
    url: 'https://www.ted.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0552_300.png',
    title: '虎嗅网',
    url: 'http://www.huxiu.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_tc3jjW9NUJa.jpg',
    title: '学信网',
    url: 'https://www.chsi.com.cn/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0588_300.png',
    title: '知网',
    url: 'http://www.cnki.com.cn/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_O7xvzF1YVvh.png',
    title: '英语四六级',
    url: 'https://cet.neea.edu.cn/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_YEcenzbzKSS.jpg',
    title: '研究生招生信息网',
    url: 'https://yz.chsi.com.cn/',
  },
];


const DEFAULT_SITES_TWO = [
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_VheuiqxgjGG.png',
    title: '腾讯视频',
    url: 'http://v.qq.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_D2hLRkFoKGy.png',
    title: '爱奇艺',
    url: 'https://www.iqiyi.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0018_300.png',
    title: '优酷',
    url: 'http://www.youku.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_ieny6l6EKNz.png',
    title: '小红书',
    url: 'https://www.xiaohongshu.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_TxhVL6FL0KR.jpg',
    title: '抖音',
    url: 'https://www.douyin.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_eAaq7OGw_dj.png',
    title: '哔哩哔哩',
    url: 'http://www.bilibili.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_P5TCuWgZizl.png',
    title: 'QQ空间',
    url: 'http://qzone.qq.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0011_300.png',
    title: '新浪微博',
    url: 'http://www.weibo.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/ai_assistant_config.t_quicklink_backpool/20210423_l6w91tz009c.png',
    title: '豆瓣',
    url: 'http://www.douban.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_VCS8rN1nQ2k.png',
    title: '知乎',
    url: 'http://www.zhihu.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_bN1yGeGzvxs.png',
    title: 'QQ音乐',
    url: 'http://y.qq.com/',
  },
  {
    img: 'https://srchstatic.imtt.qq.com/img/mini_program_spider/spider_wx32431e4d2e38cc7b.png',
    title: '网易云音乐',
    url: 'https://music.163.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_PvwLAS4SCYL.webp',
    title: '微信读书',
    url: 'https://weread.qq.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_2STuhb9A2OK.webp',
    title: 'QQ阅读',
    url: 'https://book.qq.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0072_300.png',
    title: '起点中文网',
    url: 'http://www.qidian.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_K57Cwh5ZMtd.png',
    title: 'LOFTER',
    url: 'http://www.lofter.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/1002_300.png',
    title: '腾讯体育',
    url: 'http://kbs.sports.qq.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0178_300.png',
    title: '虎扑体育',
    url: 'http://www.hupu.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_iAhbWi9jE59.png',
    title: '老黄历',
    url: 'https://www.huangli.com/',
  },
  {
    img: 'https://test.static.res.qq.com/imgUpload/pc_newtab.chains/e7b88f58_nkS4lhBrvL9.jfif',
    title: '星座屋',
    url: 'https://www.xzw.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/1236_300.png',
    title: '游戏中心',
    url: 'https://pcgame.qqbrowser.qq.com/main?origin_page_id=qb_bookmark&pre_page_id=qb_bookmark&ADTAG=newtabweb',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_zkvVsJyhxb1.png',
    title: 'QQ游戏',
    url: 'https://qqgame.qq.com/channel-hall/?ipid=&ifid=1502000005',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_Gr3__pcGLb6.png',
    title: '王者荣耀',
    url: 'https://gamer.qq.com/v2/cloudgame/game/95601?ichannel=qqllq0Fqqllq1',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_sH3xA0rPwVm.png',
    title: '和平精英',
    url: 'https://gamer.qq.com/v2/cloudgame/game/95218?ichannel=qqllq0Fqqllq1',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_JVtljTLSNTC.png',
    title: '欢乐斗地主',
    url: 'https://gamer.qq.com/v2/cloudgame/game/95179?ichannel=qqllq0Fqqllq1',
  },
];

const DEFAULT_SITES_THREE = [
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0002_300.png',
    title: '淘宝网',
    url: 'https://www.taobao.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0009_300.png',
    title: '天猫',
    url: 'https://pages.tmall.com/wow/a/act/tmall/dailygroup/1985/wupr?wh_pid=daily-223668&ali_trackid=2:mm_99396806_46570087_114947300472:1701152471_071_85526749&union_lens=lensId:PUB@1687110511@213e4ae4_0a93_188cf9f6c7a_e8c3@01@eyJmbG9vcklkIjozODg1Miiwiic3BtQiiI6Il9wb3J0YWxfdjJfcGFnZXNfYWN0aXZpdHlfb2ZmaWNpYWxfaW5kZXhfaHRtIn0ie;eventPageId:20150318020012640;recoveryid:071_1931882309@1701152471877&pageId=20150318020012640&rootPageId=20150318020012640&bxsign=tbkxVn4JteoifwfYLRmUJRleZ39SWUh7-f2A1oFhgIUdO4CBHIIWNhMI-3tQbBokEGk1iYRmCCoNU_DNUz_VuzvURNsrRrg-8CwDwsSvUu-_Tqh-VBYzIRr7KMI54iYBbw0vjCUwS5qsBS4D1TrrFWShA',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/1260_300.png',
    title: '聚划算',
    url: 'https://mos.m.taobao.com/union/jhsjx2020?pid=mm_47614295_4288816_15832025',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0386_100.png',
    title: '阿里巴巴',
    url: 'https://p4psearch.1688.com/hamlet.html?scene=6&cosite=sogoudaohang&location=mingzhan',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0301_300.png',
    title: '什么值得买',
    url: 'http://www.smzdm.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0081_300.png',
    title: '亚马逊中国',
    url: 'http://www.amazon.cn/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0024_300.png',
    title: '苏宁易购',
    url: 'http://www.suning.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58__yXzS-W5jyj.jpg',
    title: '大众点评',
    url: 'http://www.dianping.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0073_100.png',
    title: '去哪儿',
    url: 'http://www.qunar.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_q3orVBufz2u.png',
    title: '腾讯地图',
    url: 'http://map.qq.com/',
  },
  {
    img: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.chains/e7b88f58_XrCEmZ64yDy.webp',
    title: '大麦网',
    url: 'http://www.damai.cn/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0061_300.png',
    title: '携程',
    url: 'https://www.ctrip.com/?allianceid=14666&sid=418539',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/0048_300.png',
    title: '一淘网',
    url: 'http://www.etao.com/',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/1261_300.png',
    title: '爱淘宝',
    url: 'https://ai.taobao.com/?pid=mm_99396806_46570087_114948800353',
  },
  {
    img: 'http://stdl.qq.com/stdl/newtabcms/icon/1291_300.png',
    title: 'Booking',
    url: 'http://www.booking.com/',
  },
];

const GUEST_DATA = {
  timeStamp: 0,
  option: {
    layout: {
      news: 1,
      nav: 1,
      tab: 1,
    },
    bgCloud: 1,
    bgIdx: '2',
    AIbg: true,
    module: {
      top: 0,
      feeds: 1,
    },
    switches: {
      searchOpenInNewTab: 1,
      siteOpenInNewTab: 1,
    },
    luhan: {},
  },
  list: [],
  tabIdx: 0,
  isListCustom: false,
  isNewIcon: true,
};

export const GROUP_ICON_LIST = [
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_jFj9k78hzIH.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_i7sKsj7AaGE.png',
    name: 'big_file',
  },
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/publicimg/pcqb/new_tab/icon/icon_bag_default.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_MOs4ea1csV4.png',
    name: 'bag',
  },
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_ysM0gWZOx9E.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_GcnhdehBAgI.png',
    name: 'book',
  },
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_COdxRgHTQW2.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_0xh0dXEm6sp.png',
    name: 'edit',
  },
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_DQoxwC6bKx1.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_ycI0dn042hb.png',
    name: 'game',
  },
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_yosw9H4-Jzv.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_8yfHEGTRsMz.png',
    name: 'shopping',
  },
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_LK1Gi-aZvgd.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_8sxudP6xTv3.png',
    name: 'music',
  },
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_VYJ-9K7jVGM.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_iEmzF2ho4PN.png',
    name: 'video',
  },
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_1WqxDX2N8h1.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_AZTjsfOhxvi.png',
    name: 'work',
  },
  {
    defaultIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_nBzPdED-bdw.png',
    activeIcon: 'https://m4.publicimg.browser.qq.com/imgUpload/pc_newtab.group_icon_config/8f674ca4_9jxLOmRAv-u.png',
    name: 'airplane',
  },
];

const DEFAULT_GROUPS_ITEM = {
  name: '默认分组',
  icon: GROUP_ICON_LIST[0].name,
};
const DEFAULT_GROUPS = [
  {
    name: '生活娱乐',
    icon: GROUP_ICON_LIST[4].name,
  },
  {
    name: '工作学习',
    icon: GROUP_ICON_LIST[1].name,
  },
  {
    name: '购物出行',
    icon: GROUP_ICON_LIST[5].name,
  },
];

export default {
  MAX_SITE_NUM,
  DEFAULT_ICON_IMAGE,
  GUEST_DATA,
  GROUP_ICON_LIST,
  DEFAULT_SITES_TWO,
  DEFAULT_SITES_ONE,
  DEFAULT_SITES_THREE,
  DEFAULT_GROUPS_ITEM,
  DEFAULT_GROUPS,
};
