export const initContextEvent = (cb?: (e: MouseEvent) => void, hide?: () => void) => {
  document.addEventListener('contextmenu', (e: MouseEvent) => {
    const { target } = e;
    const classList = (target as HTMLElement)?.classList;
    if (!classList) {
      return;
    }

    if (classList?.contains('sites-edit-title')
    || classList?.contains('sites-edit-url')
    || classList?.contains('create-widget-search-input')
    || classList?.contains('qbsbox-input')
    ) {
      return;
    }

    if (classList?.contains('mask') || classList?.contains('modal-body')) {
      hide?.();
    }

    e.preventDefault();
    cb?.(e);
    return false;
  });
};
