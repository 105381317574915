<script lang="ts">
import { defineComponent } from 'vue';
import Widgets from '@/components/widgets/index.vue';
import { reporter } from '@/lib/dtReport';
import AssetHelper from '@/utils/asset-helper';
import { ListItem } from '@/types/pref';
import GroupBar from '@components/group-bar/index.vue';

export default defineComponent({
  name: 'QuickChain',
  components: {
    Widgets,
    GroupBar,
  },
  emits: ['setGroupEmpty'],
  data() {
    return {
      sites: [],
      defaultGroups: [],
      showAll: false,
      groupIndex: 0,
      widgetStyle: {},
      defaultActiveIndex: 0,
      blankOpen: false,
    };
  },
  computed: {
    contextGroupReportPrams() {
      return {
        position: this.groupIndex + 1,
        jump_url: '',
        name: '',
        group_id: this.groupIndex,
        group_name: this.groupIndex,
        group_position: this.groupIndex,
      };
    },
  },
  created() {
    this.initSites();
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
    // this.$bus.$off('addWidget');
  },
  methods: {
    async initSites() {
      try {
        const { isFirstComeIn, widgets = [], groups = [] } = AssetHelper.getUserData();
        if (isFirstComeIn && groups.length > 3) {
          this.defaultActiveIndex = 1;
        }

        if (isFirstComeIn) {
          AssetHelper.setIsFirstComeIn(false);
        }

        groups?.forEach((_, index) => {
          this.sites[index] = widgets?.[index] || [];
        });

        this.defaultGroups = groups || [];
        this.showAll = true;
        this.$nextTick(() => {
          reporter.reportEvent({
            eventName: 'homepv',
            businessParams: {
              total_group: this.$refs.groups.groups.length,
              total_quickstart: this.sites.reduce((acc, cur) => acc + cur.length, 0),
            },
          });
        });
      } catch (e) {
      }
    },
    async setWidget(widgets: ListItem[]) {
      const { groups } = AssetHelper.getUserData();
      await AssetHelper.updateWidgets(groups[this.groupIndex].id!, widgets);
    },
    changeGroup(index: number) {
      this.$nextTick(async () => {
        this.updateWidgetStyle(index, true);
        if (!this.sites[index]?.length) {
          this.sites[index] = await AssetHelper.getQuickLinksByIndex(index);
        }

        this.groupIndex = index;
      });
    },
    handleResize() {
      this.updateWidgetStyle(this.groupIndex, false);
    },
    updateWidgetStyle(index: number, hasAnimation: boolean) {
      const width = this.$refs.widget?.[index]?.$el?.clientWidth ?? 0;
      const translateX = -(Number(width)) * index;
      if (translateX === 0) {
        // https://tapd.woa.com/tapd_fe/10093681/bug/detail/1010093681130968599
        this.widgetStyle = {
          transition: hasAnimation ? 'transform 0.3s ease-in-out' : 'none',
        };
      } else {
        this.widgetStyle = {
          transition: hasAnimation ? 'transform 0.3s ease-in-out' : 'none',
          transform: `translateX(${translateX}px)`,
        };
      }
    },
    deleteGroup(index: number, groupLen: number) {
      this.sites.splice(index, 1);
      if (groupLen === 0) {
        return this.$emit('setGroupEmpty', groupLen);
      }
      if (this.groupIndex === index) {
        if (this.sites.length === index) {
          this.changeGroup(index - 1);
        } else {
          this.changeGroup(index);
        }
      } else if (index < this.groupIndex) {
        this.changeGroup(this.groupIndex - 1);
      }
    },
    createGroup() {
      this.sites.push([]);
      const groupLen = this.$refs.groups && this.$refs.groups.groups.length;
      this.$emit('setGroupEmpty', groupLen);
    },
    getActiveIndex(oldIndex: number, newIndex: number) {
      const { groupIndex } = this;
      const isBetween = Math.min(oldIndex, newIndex) < groupIndex && Math.max(oldIndex, newIndex) > groupIndex;
      let activeIndex = -1;
      if (groupIndex === oldIndex) {
        activeIndex = newIndex;
        return activeIndex;
      }
      if (groupIndex === newIndex) {
        activeIndex = oldIndex;
        return activeIndex;
      }
      if (!isBetween) {
        return groupIndex;
      }
      if (oldIndex < newIndex) {
        activeIndex = groupIndex - 1;
      } else if (oldIndex > newIndex) {
        activeIndex = groupIndex + 1;
      }
      return activeIndex;
    },
    movedGroup({ oldIndex, newIndex }: {
      oldIndex: number,
      newIndex: number,
    }) {
      const sites = this.sites.slice();
      this.sites = [];
      const element = sites[oldIndex];
      sites.splice(oldIndex, 1);
      sites.splice(newIndex, 0, element);
      this.sites = sites;
      const activeIndex = this.getActiveIndex(oldIndex, newIndex);
      this.changeGroup(activeIndex);
      this.$refs.groups.activeIndex = activeIndex;
    },
    async onWidgetChange(sites: any) {
      await this.setWidget(sites);
      this.sites[this.groupIndex] = sites;
    },
    getDtParams() {
      // const groups = this.$refs.groups?.groups;
      return {
        group_id: this.groupIndex,
        // group_name: curGroupData,
        group_position: this.groupIndex,
      };
    },
    getSiteKey(index: number) {
      const { groups } = AssetHelper.getUserData();
      const id = `${groups[this.groupIndex]?.id}_${index}`;
      return id;
    },
    restoreHistory(widgets, groups, isUpdate = false) {
      // if (!widgets?.length || !groups?.length) return;
      this.defaultGroups = groups || [];
      this.sites = [];
      groups?.forEach((_, index) => {
        this.sites[index] = widgets[index] || [];
      });

      this.groupIndex = 0;
      this.widgetStyle = {};
      this.$refs.groups.updateGroups(this.defaultGroups, this.groupIndex, isUpdate);
    },
  },
});
</script>

<template>
  <div v-if="showAll">
    <div class="content">
      <div
        class="content-item-wrapper"
        :style="widgetStyle"
      >
        <Widgets
          v-for="(site, index) in sites"
          :key="getSiteKey(index)"
          ref="widget"
          class="user-widget-container"
          :groupIndex="groupIndex"
          :dtReportParams="getDtParams()"
          :siteList="site || []"
          :blankOpen="blankOpen"
          :widgetIndex="index"
          :redPoint="index === 0"
          :onChange="onWidgetChange"
        />
      </div>
    </div>
    <GroupBar 
    ref="groups"
    :defaultGroups="defaultGroups"
    @select="changeGroup"
    @delete="deleteGroup"
    @create="createGroup"
    @moved="movedGroup"/>
  </div>
</template>

<style lang="less">
.content-item-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}

.content {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 460px;
}

.slide-enter-active,
.slide-enter-to,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter-to {
  transform: translateX(0);
}

.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-from {
  transform: translateX(100%);
}
</style>
