import { QB_LIGHT_ATTRIBUTE_NAME } from '@/utils/constants';
import { onMounted, onUnmounted, ref } from 'vue';

export default function useQbLightChange(callback?: (isQbLight: boolean) => void) {
  const targetNode = ref(document.querySelector('html'));
  const observer = ref<Nullable<MutationObserver>>();

  const getQbLight = () => {
    const newValue = targetNode.value?.getAttribute(QB_LIGHT_ATTRIBUTE_NAME);
    return newValue === 'true';
  };

  const isQbLight = ref(getQbLight());
  onMounted(() => {
    observer.value = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === QB_LIGHT_ATTRIBUTE_NAME) {
          isQbLight.value = getQbLight();
          callback?.(isQbLight.value);
        }
      }
    });

    observer.value.observe(targetNode.value!, { attributes: true });
  });

  onUnmounted(() => {
    observer.value?.disconnect();
    observer.value = null;
  });

  return isQbLight;
}
