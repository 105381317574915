export const CUSTOM_EVENTS = {
  VERSION_EXPIRED: 'VERSION_EXPIRED',
  UPDATE_ERROR: 'UPDATE_ERROR',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
};

export  const DEFAULT_SITE_ICON = 'https://m4.publicimg.browser.qq.com/publicimg/pcqb/new_tab/icon/default-site-icon.png';

export const DEFAULT_SITE_BG_COLOR = '#F5F5F5';

export const DEFAULT_SITE_BG_COLOR_OLD = '#B3B3B3';

export const QB_LIGHT_ATTRIBUTE_NAME = 'data-qblight';
