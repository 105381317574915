export const setIconSize = (isNoData: boolean) => {
  // 获取屏幕宽度
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  let MAX_SCREEN_WIDTH = 1088;
  const MIN_SCREEN_WIDTH = 420;
  const ICON_SIZE = 56;
  const GAP_X = 60;
  // const GAP_X = 32;
  const GAP_Y = 56;
  let maxWidth = 1064;
  if (isNoData) {
    MAX_SCREEN_WIDTH = 750;
    maxWidth = 750;
  }
  if (screenWidth >= MAX_SCREEN_WIDTH) {
    document.documentElement.style.setProperty('--icon-size', `${ICON_SIZE}px`);
    document.documentElement.style.setProperty('--icon-gap-x', `${GAP_X}px`);
    document.documentElement.style.setProperty('--icon-gap-y', `${GAP_Y}px`);

    return;
  }

  // 计算图标大小
  let iconSize = screenWidth / MAX_SCREEN_WIDTH * ICON_SIZE;
  let iconGapY = screenWidth / MAX_SCREEN_WIDTH * GAP_Y;
  let iconGapX = screenWidth / MAX_SCREEN_WIDTH * GAP_X + 16; // 三屏下适配

  // 设置最小值
  iconSize = Math.max(iconSize, 48);
  iconGapY = Math.max(iconGapY, 32);
  iconGapX = Math.max(iconGapX, 0);


  if (screenWidth <= maxWidth) {
    maxWidth = screenWidth;
  }
  if (screenWidth <= MIN_SCREEN_WIDTH) {
    maxWidth = MIN_SCREEN_WIDTH;
  }

  // 将结果存储在:root CSS变量中
  document.documentElement.style.setProperty('--icon-size', `${iconSize}px`);
  document.documentElement.style.setProperty('--icon-gap-x', `${iconGapX}px`);
  document.documentElement.style.setProperty('--icon-gap-y', `${iconGapY}px`);
};
