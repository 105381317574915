import type { GroupItem, ListItem, PrefUserData, TodoItem, Weather } from '@/types/pref';
import QbAPI from './qb-api';
import RpcHelper from './rpc-helper';
import util, { base64ToObject, genUuid, isQB, isSogou, objectToBase64, safeJSONParse, standardErrorHandler } from './util';
import { exception } from '@tencent/react-error-boundary';
import global from '@/configs/global';
import { doReport } from '@/lib/lclog';
import { GetTabsRsp, MigrationTabData, QuickLink, QuickLinkSize } from '@/types/tab';
import { getLocationReq } from './store';
import { WIDGET_COMPONENT_NAME } from '@/types';
import { DEFAULT_SITE_BG_COLOR_OLD } from './constants';
import { rgbToHex } from './color';

const enum EStorageKey {
  GROUPS = 'groups',
  WIDGETS = 'widgets',
  TODO = 'todoList',
  WEATHER = 'weather',
  IS_FIRST_COME_IN = 'isFirstComeIn',
  VERSION = 'user_data_version',
};

const IS_QB_OR_SOGOU = isQB() || isSogou();

export default class AssetHelper {
  private static userData: PrefUserData = {
    list: [],
    groups: [],
    widgets: [],
    isFirstComeIn: false,
    version: 0,
  };
  private static cityName: string;

  public static getUserData() {
    return AssetHelper.userData;
  }

  public static getIsFirstComeIn() {
    return !!AssetHelper.userData?.isFirstComeIn;
  }

  @exception(standardErrorHandler)
  public static setIsFirstComeIn(isFirstComeIn = false) {
    window.localStorage.setItem(EStorageKey.IS_FIRST_COME_IN, JSON.stringify(isFirstComeIn));
  }

  @exception(standardErrorHandler)
  public static setWeatherData(weather: Weather) {
    window.localStorage.setItem(EStorageKey.WEATHER, JSON.stringify(weather));
  }

  @exception(standardErrorHandler)
  public static getWeatherData() {
    const weatherData = window.localStorage.getItem(EStorageKey.WEATHER);

    return safeJSONParse(weatherData!, {});
  }

  @exception(standardErrorHandler)
  public static async getAssets() {
    if (IS_QB_OR_SOGOU) {
      const data = await AssetHelper.getAssetFromRemote();
      if (data) {
        return data;
      }
    }

    return AssetHelper.getAssetsFromLocal(IS_QB_OR_SOGOU);
  }

  @exception(standardErrorHandler)
  public static async getQuickLinksByIndex(groupIndex: number) {
    if (!IS_QB_OR_SOGOU || Array.isArray(AssetHelper.userData.widgets?.[groupIndex])) {
      return AssetHelper.userData.widgets[groupIndex];
    }

    const groupId = AssetHelper.userData.groups[groupIndex].id;
    const { quick_links: data } = await RpcHelper.getTabQuickLinks(groupId!);
    const { widgets = [], todoList = [] } = AssetHelper.parseQuickLinksFromRemote(data, groupId!);
    AssetHelper.userData.widgets[groupIndex] = widgets;
    if (Array.isArray(AssetHelper.userData.todoList)) {
      AssetHelper.userData.todoList = [...AssetHelper.userData.todoList!, ...todoList];
    } else {
      AssetHelper.userData.todoList = todoList;
    }

    setTimeout(() => {
      AssetHelper.setAllDataToLocal(AssetHelper.userData);
    });

    return AssetHelper.userData.widgets[groupIndex];
  }

  public static async restoreHistoryGroupsAndSites() {
    return await AssetHelper.getAssets();
  }

  public static async updateGroups(groups: GroupItem[], editIndexes: number[] = []) {
    const newGroup = groups?.filter(item => !!item).map(item => ({ ...item, id: item.id?.toString() ?? genUuid() }))
      || [];
    if (IS_QB_OR_SOGOU) {
      const { version } = await RpcHelper.updateTabs(newGroup, AssetHelper.userData.version!);
      AssetHelper.updateVersion(version);
    }

    AssetHelper.userData.groups = newGroup;
    AssetHelper.setDataToStorage(EStorageKey.GROUPS, newGroup);

    if (!Array.isArray(editIndexes) || editIndexes?.length === 0) {
      return;
    }

    if (editIndexes.length === 1 && AssetHelper.userData.widgets?.length >= editIndexes[0]) {
      AssetHelper.userData.widgets.splice(editIndexes[0], 1);
    }

    // 防止两者之间个数不匹配导致的数组操作错误
    if (newGroup.length !== AssetHelper.userData.widgets?.length) {
      newGroup.forEach((_, index) => {
        if (!AssetHelper.userData.widgets[index]) {
          AssetHelper.userData.widgets[index] = undefined as any;
        }
      });

      AssetHelper.userData.widgets.length = newGroup.length;
    }

    if (editIndexes.length === 2) {
      const element = AssetHelper.userData.widgets[editIndexes[0]];
      AssetHelper.userData.widgets.splice(editIndexes[0], 1);
      AssetHelper.userData.widgets.splice(editIndexes[1], 0, element);
    }

    AssetHelper.setDataToStorage(EStorageKey.WIDGETS, AssetHelper.userData.widgets);
  }

  public static async updateWidgets(groupId: string, widgets: ListItem[]) {
    const { groups, widgets: currentWidgets = [] } = AssetHelper.userData || {};
    const index = groups.findIndex(item => item.id === groupId);
    if (index === -1) {
      return;
    }

    const quickLinks: QuickLink[] = [];
    currentWidgets[index] = widgets?.map((item) => {
      const id = item.id?.toString() ?? genUuid();
      const linkItem: QuickLink = {
        id,
        title: item.title,
        url: item.url,
        img: item.img!,
        icon_background: item.iconBg,
        size: AssetHelper.parseItemSize(item.size),
        quick_link_type: item.component || WIDGET_COMPONENT_NAME.site,
      };

      const { componentData, ...extraItem } = item;
      if (componentData) {
        linkItem.quick_link_data = objectToBase64(componentData);
      }

      quickLinks.push(linkItem);
      return { ...extraItem, id };
    });

    if (IS_QB_OR_SOGOU) {
      const { version } = await RpcHelper.updateQuickLinks(groupId, quickLinks, AssetHelper.userData.version!);
      AssetHelper.updateVersion(version);
    }

    AssetHelper.userData.widgets = currentWidgets;
    AssetHelper.setDataToStorage(EStorageKey.WIDGETS, currentWidgets);
  }

  public static async updateComponentData(key: string, id: string, value: any, isOnlyLocal = false) {
    const { todoList = [] } = AssetHelper.userData || {};
    if (key === WIDGET_COMPONENT_NAME.todo) {
      const index = todoList?.findIndex?.(item => item.id === id) || -1;
      if (value) {
        if (index === -1) {
          todoList.push(value);
        } else {
          todoList[index] = value;
        }
      } else {
        todoList.splice(index, 1);
      }
    }

    if (IS_QB_OR_SOGOU && !isOnlyLocal) {
      const { version } = await RpcHelper.updateQuickLinksData({
        id,
        data: objectToBase64(value),
      }, AssetHelper.userData.version!);
      AssetHelper.updateVersion(version);
    }

    if (key === WIDGET_COMPONENT_NAME.todo) {
      AssetHelper.userData.todoList = todoList;
      AssetHelper.setDataToStorage(EStorageKey.TODO, todoList);
    }
  }

  public static getAssetsFromLocal(isReport = false) {
    AssetHelper.userData = AssetHelper.getDataFromStorage();
    if (isReport) {
      doReport({
        action: 'getAssetsFromLocal',
        data: {
          data: AssetHelper.userData,
        },
      });
    }

    if (!IS_QB_OR_SOGOU && AssetHelper.userData?.isFirstComeIn) {
      setTimeout(() => {
        AssetHelper.setAllDataToLocal(AssetHelper.userData);
      });
    }

    return AssetHelper.userData;
  }

  @exception(standardErrorHandler)
  public static async getAssetFromRemote() {
    const data = await RpcHelper.getTabs();
    doReport({
      action: 'getAssets',
      data: {
        data,
      },
    });

    const { version = 0 } = data;
    if (!version) {
      AssetHelper.userData = await AssetHelper.getDataFromPrefs();
      AssetHelper.userData.version = await AssetHelper.setAllDataToRemote(AssetHelper.userData);
    } else {
      AssetHelper.userData = AssetHelper.parseDataFromRemote(data);
    }

    setTimeout(() => {
      AssetHelper.setAllDataToLocal(AssetHelper.userData);
    });

    return AssetHelper.userData;
  }

  private static parseDataFromRemote(data: GetTabsRsp) {
    doReport({
      action: 'parseDataFromRemote start',
      data: {
        data,
      },
    });

    const { tabs, first_quick_links: quickLinks, version } = data;
    const groups = tabs || [] as GroupItem[];

    const { widgets = [], todoList } = AssetHelper.parseQuickLinksFromRemote(quickLinks, groups[0]?.id as string);

    const userData = {
      list: [],
      groups,
      widgets: [widgets],
      todoList,
      isFirstComeIn: false,
      version,
    } satisfies PrefUserData;

    doReport({
      action: 'parseDataFromRemote end',
      data: {
        userData,
      },
    });

    return userData;
  }

  private static parseQuickLinksFromRemote(quickLinks: QuickLink[], groupId: string) {
    doReport({
      action: 'parseQuickLinksFromRemote start',
      data: {
        groupId,
        quickLinks,
      },
    });

    const widgets: ListItem[] = [];
    const todoList: TodoItem[] = [];
    quickLinks?.forEach((item) => {
      const { id, title, url, img, icon_background: iconBg, size, quick_link_type: type,
        quick_link_data: byteData } = item;
      widgets.push({
        id,
        title,
        url,
        img,
        iconBg: AssetHelper.formatIconBgForOldData(iconBg, img, title) || iconBg,
        type: type !== WIDGET_COMPONENT_NAME.site ? 'component' : undefined,
        size: AssetHelper.formatItemSize(size!),
        component: type,
      });

      const data = base64ToObject(byteData!);
      if (type === WIDGET_COMPONENT_NAME.site || !data) {
        return;
      }

      if (type === WIDGET_COMPONENT_NAME.todo) {
        const todoItem = data as TodoItem;
        if (todoItem) {
          todoList.push(todoItem);
        }
      }
    });

    const userData = { widgets, todoList };

    doReport({
      action: 'parseQuickLinksFromRemote end',
      data: {
        groupId,
        userData,
      },
    });

    return userData;
  }

  @exception(standardErrorHandler)
  private static async setAllDataToRemote(data: PrefUserData) {
    const { groups, widgets, todoList } = data || {};
    const migrationTabs: MigrationTabData[] = [];
    groups?.forEach((group, index) => {
      if (!group) {
        return;
      }

      const tabData: MigrationTabData = {
        tab: { ...group, id: group.id ? group.id.toString() : genUuid() },
        quick_links: [],
      };

      widgets?.[index]?.forEach(((item) => {
        let linkData;
        if (item.component === WIDGET_COMPONENT_NAME.todo) {
          linkData = todoList?.find(todo => todo.id === item.id);
          if (linkData) {
            linkData.component = item.component;
          }
        }

        const quickLink: QuickLink = {
          id: item.id?.toString() || genUuid(),
          img: item.img!,
          title: item.title!,
          url: item.url!,
          icon_background: item.iconBg,
          quick_link_type: item.component || WIDGET_COMPONENT_NAME.site,
          size: AssetHelper.parseItemSize(item.size),
        };

        if (linkData) {
          quickLink.quick_link_data = objectToBase64(linkData);
        }

        tabData.quick_links.push(quickLink);
      }));

      migrationTabs.push(tabData);
    });

    const { version } = await RpcHelper.migrationUserData(migrationTabs);

    doReport({
      action: 'setAllDataToRemote',
      data: {
        data,
      },
    });

    return version;
  }

  private static parseItemSize(size: string | undefined): QuickLinkSize {
    const [length = 1, height = 1] = size?.split('x')?.map(x => parseInt(x, 10)) || [];

    return {
      length,
      height,
    };
  }

  private static formatItemSize(size: QuickLinkSize): string | undefined {
    const { length, height } = size || {};

    return length ? `${length}x${height}` : undefined;
  }

  @exception(standardErrorHandler)
  private static setAllDataToLocal(data: PrefUserData) {
    const { groups, widgets, todoList, isFirstComeIn, version } = data;
    AssetHelper.setDataToStorage(EStorageKey.GROUPS, groups);
    AssetHelper.setDataToStorage(EStorageKey.WIDGETS, widgets);
    AssetHelper.setDataToStorage(EStorageKey.TODO, todoList);
    AssetHelper.setDataToStorage(EStorageKey.VERSION, version);
    AssetHelper.setIsFirstComeIn(isFirstComeIn);
  }

  private static updateVersion(version: number) {
    AssetHelper.userData.version = version;
    AssetHelper.setDataToStorage(EStorageKey.VERSION, version);
  }

  @exception(standardErrorHandler)
  private static setDataToStorage(key: string, data: any) {
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  @exception(standardErrorHandler)
  private static async getDataFromPrefs() {
    let userData = (await QbAPI.getUserData()) || {
      groups: [],
      widgets: [],
      list: [],
      isFirstComeIn: true,
    };

    if (!Array.isArray(userData.list)) {
      userData.list = [];
    }

    if (isSogou()) {
      await AssetHelper.getCityName();
    }

    userData.list.forEach((item) => {
      if (item?.url === 'http://video.browser.qq.com/live/beauty/?ADTAG=newtabweb') {
        Object.assign(item, {
          url: 'https://now.qq.com/pcweb/index.html?from=98002&ADTAG=gdh-kz',
        });
      } else if (item?.url?.includes('union.click.jd.com')) {
        Object.assign(item, {
          url: 'https://union-click.jd.com/jdc?e=0&p=AyIPZRprFDJWWA1FBCVbV0IUEEULRFRBSkAOClBMW2VrH0oCEQQWHDgSGG4AD3oAaEZwQyhNVxkyEQ9dH1wSARcGZRhTHQYUBlQYUiUyEgZlUDUUMhcHVx5TFgISAWUbXh0AEwZVE1gQCxEPZRxrTld8AAAbUhADFAAGG15HAyI3ZSs%3D&t=W1dCFBBFC0RUQUpADgpQTFs%3D',
        });
      } else if (isSogou() && item?.title === '百度' && AssetHelper.cityName?.includes('北京')) {
        Object.assign(item, {
          url: 'https://www.baidu.com/?tn=87048150_dg&ch=2',
        });
      }
    });

    userData = AssetHelper.formatData(userData);

    doReport({
      action: 'getDataFromPrefs',
      data: {
        userData,
      },
    });

    return userData;
  }

  @exception(standardErrorHandler)
  private static getDataFromStorage() {
    const localGroup = window.localStorage.getItem(EStorageKey.GROUPS);
    const localWidget = window.localStorage.getItem(EStorageKey.WIDGETS);
    const todoList = window.localStorage.getItem(EStorageKey.TODO);
    const isFirstComeIn = window.localStorage.getItem(EStorageKey.IS_FIRST_COME_IN);
    const version = window.localStorage.getItem(EStorageKey.VERSION);
    const userData: PrefUserData = AssetHelper.formatData({
      list: [],
      groups: safeJSONParse(localGroup!, []) || [],
      widgets: safeJSONParse(localWidget!, []) || [],
      todoList: safeJSONParse(todoList!, []) || [],
      isFirstComeIn: isFirstComeIn === null,
      version: version ? parseInt(version!, 10) : 0,
    });

    doReport({
      action: 'getDataFromStorage',
      data: {
        userData,
      },
    });

    return userData;
  }

  private static formatData(data: PrefUserData) {
    const { groups, widgets, list, isFirstComeIn } = data;
    if (!isFirstComeIn) {
      return data;
    }

    const hasSites = list?.length > 0;
    if (!groups?.length) {
      Object.assign(
        data,
        {
          groups: hasSites ? [global.DEFAULT_GROUPS_ITEM, ...global.DEFAULT_GROUPS] : global.DEFAULT_GROUPS,
        },
      );
    }

    if (!widgets?.length) {
      const defaultSites = [global.DEFAULT_SITES_TWO, global.DEFAULT_SITES_ONE, global.DEFAULT_SITES_THREE];
      Object.assign(
        data,
        {
          widgets: hasSites ? [list, ...defaultSites] : defaultSites,
        },
      );
    }

    return data;
  }

  private static async getCityName() {
    if (!AssetHelper.cityName) {
      AssetHelper.cityName = await getLocationReq();
    }

    return AssetHelper.cityName;
  }

  @exception(standardErrorHandler)
  private static formatIconBgForOldData(iconBg: string | undefined, img: string, title: string) {
    if (img || safeJSONParse(iconBg)) {
      return iconBg;
    }

    let bgColor = DEFAULT_SITE_BG_COLOR_OLD;
    if (iconBg) {
      const rgbaMatch = iconBg.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*\d*?\)/);

      if (rgbaMatch) {
        const r = parseInt(rgbaMatch[1], 10);
        const g = parseInt(rgbaMatch[2], 10);
        const b = parseInt(rgbaMatch[3], 10);
        bgColor = rgbToHex(r, g, b);
      }
    }

    return JSON.stringify({ bgColor, textColor: '#fff', text: util.getFirstWord(title).toUpperCase() });
  }
}
