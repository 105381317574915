<template>
  <div class="color-picker">
    <div class="preset-colors">
      <div v-for="color in presetColors" :key="color" :style="{ backgroundColor: color }" class="color-swatch"
        @click="selectColor(color)"></div>
      <div class="color-swatch color-more" @click="isShowPanel = !isShowPanel" />
    </div>
    <div v-show="isShowPanel" class="color-panel">
      <div class="color-gradient"
        :style="{ background: `linear-gradient(to top, black, transparent), linear-gradient(to right, white, hsl(${currentHue}, 100%, 50%))` }"
        ref="colorGradient" @mousedown="startGradientDrag">
        <div class="color-indicator"
          :style="{ left: `${currentSaturation * 100}%`, top: `${(1 - currentBrightness) * 100}%` }"></div>
      </div>
      <div class="color-edit">
        <div v-if="isSupported" class="color-pen" @click="openEyeDropper" />
        <div class="color-select" :style="{ background: `rgb(${currentRgb.r}, ${currentRgb.g}, ${currentRgb.b})` }" />
        <div class="color-slider" ref="colorSlider" @mousedown="startSliderDrag">
          <div class="slider-indicator"
            :style="{ left: `${currentHue / 360 * 100}%`, background: `rgb(${currentRgb.r}, ${currentRgb.g}, ${currentRgb.b})` }">
          </div>
        </div>
      </div>
      <div class="rgb-input">
        <label>RGB</label>
        <input class="first-input" type="number" v-model.number="currentRgb.r" min="0" max="255" />
        <input type="number" v-model.number="currentRgb.g" min="0" max="255" />
        <input class="last-input" type="number" v-model.number="currentRgb.b" min="0" max="255" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import './index.less';
</style>

<script setup lang="ts">
import { defineProps, defineEmits, ref, reactive, onMounted, watch, PropType } from 'vue';
import { hexToRgb, hsbToRgb, rgbToHex, rgbToHsb } from '@/utils/color';
import useEyeDropper from './hooks/use-eye-dropper';

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  presetColors: {
    type: Array as PropType<string[]>,
    default: () => [
      '#F5341A', '#FF8D07', '#FFC807', '#FFE380', '#78E0A3', '#02C875', '#56CCF2',
      '#3284FF', '#205AEF', '#998DD9', '#7441F4', '#000000', '#F5F5F5',
    ]
  },
});

const emit = defineEmits(['change']);
const currentRgb = reactive(hexToRgb(props.value));
const colorGradient = ref<HTMLElement | null>(null);
const colorSlider = ref<HTMLElement | null>(null);
const currentHue = ref(0); // 色调
const currentSaturation = ref(1); // 饱和度
const currentBrightness = ref(1); // 亮度
const isShowPanel = ref(false);
const { isSupported, open } = useEyeDropper();

const selectColor = (color: string) => {
  const { r, g, b } = hexToRgb(color);
  currentRgb.r = r;
  currentRgb.g = g;
  currentRgb.b = b;
  updateHsbFromRgb();
};


const openEyeDropper = async () => {
  try {
    const result = await open();
    selectColor(result!.sRGBHex);
  } catch (e) {
    console.warn(e);
  }
};

const startGradientDrag = () => {
  const onMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    if (colorGradient.value) {
      const rect = colorGradient.value.getBoundingClientRect();
      const x = Math.min(Math.max(e.clientX - rect.left, 0), rect.width);
      const y = Math.min(Math.max(e.clientY - rect.top, 0), rect.height);
      currentSaturation.value = x / rect.width;
      currentBrightness.value = 1 - y / rect.height;
      updateRgbFromHsb();
    }
  };

  const onMouseUp = () => {
    document.removeEventListener('mousedown', onMouseMove);
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  document.addEventListener('mousedown', onMouseMove);
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
};

const startSliderDrag = () => {
  const onMouseMove = (e: MouseEvent) => {
    document.body.style.userSelect = 'none'; // 禁用文本选择
    if (colorSlider.value) {
      const rect = colorSlider.value.getBoundingClientRect();
      const x = Math.min(Math.max(e.clientX - rect.left, 0), rect.width);
      currentHue.value = (x / rect.width) * 360;
      updateRgbFromHsb();
    }
  };

  const onMouseUp = () => {
    document.body.style.userSelect = ''; // 恢复文本选择
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
};

const updateRgbFromHsb = () => {
  const { r, g, b } = hsbToRgb(currentHue.value, currentSaturation.value * 100, currentBrightness.value * 100);
  currentRgb.r = r;
  currentRgb.g = g;
  currentRgb.b = b;
};

const updateHsbFromRgb = () => {
  const { h, s, v } = rgbToHsb(currentRgb.r, currentRgb.g, currentRgb.b);
  currentHue.value = h * 360;
  currentSaturation.value = s;
  currentBrightness.value = v;
};

onMounted(() => {
  updateHsbFromRgb();
});

watch([currentHue, currentSaturation, currentBrightness], () => {
  updateRgbFromHsb();
});

watch(currentRgb, () => {
  emit('change', rgbToHex(currentRgb.r, currentRgb.g, currentRgb.b))
});
</script>