<script lang="ts">
import util, { safeJSONParse } from '@/utils/util';
import { defineComponent } from 'vue';
import xssFilters from 'xss-filters';
import type { PropType } from 'vue';
import { Operation, Position } from '@/types';
import RadioButton from '@/components/radio/index.vue';
import { ListItem } from '@/types/pref';
import ColorPicker from '@/components/color-picker/index.vue';
import { DEFAULT_SITE_BG_COLOR, DEFAULT_SITE_ICON } from '@/utils/constants';
import lcLog from '@/lib/lclog';
import { hexToRgb } from '@/utils/color';

export default defineComponent({
  name: 'EditWidget',
  components: {
    RadioButton,
    ColorPicker,
  },
  props: {
    position: {
      type: Object as PropType<Position> | null | undefined,
      default: () => (null),
    },
    zIndex: {
      type: Number,
      default: 1000,
    },
    widgetData: {
      type:  Object as PropType<ListItem>,
      default: () => ({}),
    },
    reportParams: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'confirm'],
  data() {
    const { text, bgColor, textColor } = safeJSONParse(this.widgetData?.iconBg, {});
    return {
      url: this.widgetData?.url || '',
      title: this.widgetData?.title || '',
      img: this.widgetData?.img || DEFAULT_SITE_ICON,
      hasIcon: !this.widgetData?.url ||(!bgColor && !!this.widgetData.img),
      customBgColor: bgColor || DEFAULT_SITE_BG_COLOR,
      iconText: text || '',
      iconTextColor: textColor || '',
    };
  },
  computed: {
    modalStyle() {
      return this.position ? { ...this.position } : {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };
    },
    disabled() {
      const { url, title = '' } = this;
      const isValid = util.isUrl(url) || util.isDomain(url);
      return !title.trim() || !isValid;
    },
    dtExposureParams() {
      const { url, title } = this.widgetData;
      return util.genQueryString({
        edit_add: url ? Operation.EDIT : Operation.ADD,
        ...this.reportParams,
        jump_url: this.url || url,
        name: title || this.title,
      });
    },
  },
  watch: {
    customBgColor: {
      handler(newVal) {
        if (!newVal) {
          return '';
        }

        const { r, g, b } = hexToRgb(newVal);
        this.iconTextColor = r >= 127 && g >= 127 && b >= 127 ? '#242424' : '#FFFFFF';
      },  
      immediate: true,
    },
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      const { url, title, img, hasIcon, iconText, customBgColor, iconTextColor } = this;
      if (!url || !title) {
        return;
      }
      this.$emit('confirm', {
        title: xssFilters.inHTMLData(util.encodeHTML(title) || title || util.parseUrl(url).host || ''),
        url: xssFilters.inDoubleQuotedAttr(util.isDomain(url) ? `http://${url}` : url),
        img,
        iconBg: hasIcon ? '' : JSON.stringify({ text: iconText, bgColor: customBgColor, textColor: iconTextColor }),
      });
      this.close();
    },
    cancel() {
      this.close();
    },
    originIconChange(value: boolean) {
      this.hasIcon = value;
    },
    customIconChange(value: boolean) {
      this.hasIcon = !value;
    },
    updateCustomBgColor(value: string) {
      this.customBgColor = value;
    },
    async handleUrlInput() {
      try {
        const { url } = this;
        const isValid = util.isUrl(url) || util.isDomain(url);
        if (!isValid) {
          return;
        }

        const realUrl = xssFilters.inDoubleQuotedAttr(util.isDomain(url) ? `http://${url}` : url);
        const res: any = await util.getIconAndTitleByUrl([realUrl]);
        if (res[realUrl]?.thumbnail) {
           this.img = res[realUrl].thumbnail;
        }
      } catch (err) {
        lcLog.infoAll(err);
      }
    },
  },
});
</script>
<template>
  <div class="modal-container">
    <div
      class="mask"
      :style="{ zIndex: zIndex }"
      @click="close"
      @contextmenu.prevent.stop=""
    />
    <div
      class="modal-body modal-box-shadow"
      :dt-params="dtExposureParams"
      dt-eid="quickstart_edit_add_panel"
      :style="modalStyle"
      @contextmenu.prevent.stop=""
    >
      <div class="modal-content">
        <div class="form-item">
          <input
            v-model="title"
            class="form-input"
            placeholder="请输入网站名称 "
            type="text"
            @keyup.enter="confirm"
          >
        </div>
        <div class="form-item">
          <input
            v-model="url"
            class="form-input"
            placeholder="请输入网站地址"
            type="text"
            @keyup.enter="confirm"
            @input="handleUrlInput"
          >
        </div>
        <div class="custom-box">
            <span class="custom-title">图标类型</span>
            <div class="custom-content">
              <RadioButton :value="hasIcon" :canUncheck="false" @change="originIconChange"/>
              <img class="icon-box" :src="img" alt="" />
              <span>原有图标</span>
            </div>
            <span class="split-line"/>
            <div class="custom-content">
              <RadioButton :value="!hasIcon" :canUncheck="false" @change="customIconChange" />
              <div class="icon-box" :style="{ backgroundColor: customBgColor, color: iconTextColor }">{{ iconText }}</div>
              <div class="custom-edit">
                <span>图标文字不超过2字</span>
                <input v-model="iconText" maxlength="2" class="custom-input" type="text" placeholder="请输入" />
              </div>
            </div>
            <ColorPicker v-if="!hasIcon" class="color-picker-container" :value="customBgColor" @change="updateCustomBgColor" />
          </div>
      </div>
      <div class="modal-footer">
        <button
          class="modal-btn btn-primary"
          dt-keep-report="true"
          dt-imp-ignore="true"
          dt-eid="confirm"
          :dt-params="dtExposureParams"
          :disabled="disabled"
          @click="confirm"
        >
          确定
        </button>
        <button
          class="modal-btn"
          dt-eid="cancel"
          dt-imp-ignore="true"
          dt-keep-report="true"
          :dt-params="dtExposureParams"
          @click="cancel"
        >
          取消
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
  @import './index.less';
</style>
