import { createApp } from 'vue';
import skinPlugin from '@tencent/skin-plugin';
import { doReportPv } from '@/lib/lclog';
import { reporter } from '@/lib/dtReport';
import '@/assets/style/reset.css';
import '@/lib/personal';
import './style.less';
import App from './App.vue';
import bus from '@/common/bus';

const bgNode = document.getElementById('qb-bg');
if (bgNode?.style?.backgroundImage === '') {
  skinPlugin.init('#qb-bg');
  document.body.classList.add('no-wallpaper');
}

const app = createApp(App);
app.config.globalProperties.$bus = bus;
app.mount('#app');

doReportPv({
  location: window.location.href,
  client: `${window.innerWidth}*${window.innerHeight}`,
  screen: `${window.screen.width}*${window.screen.height}`,
  pixelRatio: window.devicePixelRatio,
});

reporter.setPage({
  pgid: 'newpage',
}, document.body).observe('newpage');

document.cookie = 'is_new_tab=true; expires=Thu, 01 Jan 2099 00:00:00 GMT; path=/';
document.cookie = 'mode=2; expires=Thu, 01 Jan 2099 00:00:00 GMT; path=/';
