<script lang="ts">
import util, { safeJSONParse } from '@/utils/util';
import { defineComponent, PropType } from 'vue';
import { ListItem } from '@/types/pref';
import { DEFAULT_SITE_BG_COLOR } from '@/utils/constants';

export default defineComponent({
  name: 'WidgetComp',
  components: {
  },
  props: {
    widget: {
      type: Object as PropType<ListItem>,
      default: () => ({}),
    },
    groupIndex: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    blankOpen: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      showModal: false,
      showEditModal: false,
      showContextGroupModal: false,
      showContextWidgetModal: false,
      contextPosition: null,
      editPosition: null,
      position: null,
      drag: false,
      editIndex: -1,
      widgetData: {},
    };
  },
  computed: {
    openTarget() {
      return this.blankOpen ? '_blank' : '_self';
    },
  },
  methods: {
    getUrlObj(item: ListItem) {
      let urlObj: any = {};
      try {
        urlObj = new window.URL(item.url);
      } catch (err) {
        // catch error
      }
      return urlObj;
    },
    getSiteUrl(item: ListItem) {
      if (!item) return '';
      const urlObj = this.getUrlObj(item);
      const url = urlObj.href || '';
      return url;
    },
    getSiteTitle(item: ListItem) {
      if (!item) return '';
      const urlObj = this.getUrlObj(item);
      return item.title || urlObj.hostname || '';
    },
    hasBgImg(item: ListItem) {
      const { bgColor } = safeJSONParse(item?.iconBg, {});
      return bgColor ? false : !!item?.img;
    },
    getSiteStyle(item: ListItem) {
      const { bgColor, textColor = '' } = safeJSONParse(item?.iconBg, {});
      if (bgColor) {
        return `background: ${bgColor}; color: ${textColor}`;
      }
      
      return item?.img ? `background-image: url(${item.img})` : `background: ${DEFAULT_SITE_BG_COLOR}`;
    },
    getCustomIconText(item: ListItem) {
      const { bgColor, text } = safeJSONParse(item?.iconBg, {});
      if (bgColor) {
        return text || '';
      }

      return item?.img ? '' : util.getFirstWord(this.getSiteTitle(item)).toUpperCase();
    },
    getDtParams(item: ListItem, index: number) {
      const title = this.getSiteTitle(item);
      const url = this.getSiteUrl(item);
      const dtParams = util.genQueryString({
        position: index + 1,
        jump_url: url,
        name: title,
        group_id: this.groupIndex,
        group_name: this.groupIndex,
        group_position: this.groupIndex,
      });
      return dtParams;
    },
  },
});
</script>
<template>
  <a
    :key="index"
    dt-eid="quickstart"
    dt-imp-ignore="true"
    :target="openTarget"
    :href="getSiteUrl(widget)"
    :dt-params="getDtParams(widget, index)"
    :data-origin="getUrlObj(widget).origin"
    :class="['site-item', hasBgImg(widget) ? '' : 'no-icon']"
    :data-log="`SITES.${index + 1}`"
  >
    <span :style="getSiteStyle(widget)">{{ getCustomIconText(widget) }}</span>
    <i class="site-title">{{ getSiteTitle(widget) }}</i>
  </a>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.ghost {
  opacity: 0.5;
  cursor: pointer !important;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
</style>
